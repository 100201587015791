.block-sortable.show-mode {
  border: 4px solid #666;
  margin: 4px;
  position: relative;
  min-height: 5em;

  .zone-number {
    position: absolute;
    right: 0px;
    padding: .1em .5em;
    background-color: #666;
    color: white;
    font-weight: 800;
    font-size: 2em;
  }
}