body.page-brand {
  .rd-top-background {
    .tr-block {
      img.image-icon {
        margin-right:1em;
      }
    }
  }

  .tr-top-section {
    .tr-block {
      img.image-icon {
        margin-left:0.5em;
        margin-right:0.5em;
      }
    }
  }
}