.home-card {
  margin-bottom:2em;
  padding: 1em;
  border-radius: 1em;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;

  h4 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.4em
  }

  h5 {
    text-transform: uppercase;
    font-size: 1em
  }


}

.home-card.home-pos0, .home-card.home-pos3 {
  height: 500px;
  padding-top:350px;
}

.home-card.home-pos1, .home-card.home-pos2 {
  height: 300px;
  padding-top: 150px;
}

/* ________________________________ */
/* __ TEASER ______________________ */
/* ________________________________ */
.home-teaser {
  min-height: 680px;
  background-size: cover;
  background-position-x: center;
  padding-top: 0;
  padding-bottom: 0;

  .cloud-top {
    min-height: 680px;
    background-size: 100%;
    background-position-y: top;
    background-repeat: no-repeat;

    .cloud-bottom {
      min-height: 680px;
      background-size: 100%;
      background-position-y: bottom;
      background-repeat: no-repeat;
      text-align: right;
      vertical-align: middle;
      padding-top: 10em;

      h3 {
        font-size:1.8em;
        text-transform: uppercase;
      }

      h5 {
        font-size:3em;
        font-family: 'Quicksand';
      }

      a {
        display: inline-block;
        background-color: #000;
        color: #fff;
        padding: 0px 30px;
        font-size: 0.8em
      }

      a:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }
}

