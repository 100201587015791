
.contact-form {
  .form-group {
    margin-top: 1em;
    input.contact-input {
      background: transparent;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 2px solid black;
      border-radius: 0;
      color: black;
      padding: 0.3em;
      padding-left: 0;
    }
    textarea.contact-input {
      background: white;
      border: 2px solid black;
      border-radius: 1em;
      color: black;
      padding: 1em;
    }
  }
}



.rental-top {
  margin-top: 1em;

  .rental-date {
    input {
      margin-top: 1em;
      border:2px solid black;
      padding: 0.4em;
      padding-left:1em;
      border-radius: 1.4em;
      width: 100%;
    }
  }

  .label-matos {
    font-family: 'Quicksand';
    background-color: #e0e0e0;
    border-radius: 1.4em;
    padding: 0.4em;
    padding-left:1em;
    text-align: center;
    text-transform: uppercase;
  }

  .label-matos > label {
    padding: 0;
    margin:0;
  }

  .form-group.field-matos {
    label {
      display:none;
    }

    input {
      margin-top: 1em;
      border:2px solid black;
      padding: 0.4em;
      padding-left:1em;
      border-radius: 1.4em;
      width: 100%;
    }

    textarea {
      margin-top: 1em;
      border:2px solid black;
      padding: 0.4em;
      padding-left:1em;
      border-radius: 1.4em;
      width: 100%;
      height: 6.1em;
    }
  }
}





#ExampleCaptcha_CaptchaImageDiv {
  border: 2px solid black;
  border-radius: 1em;
  overflow:hidden;
  background-color: white;

  div.BDC_CaptchaImageDiv {
    margin-top:5px !important;
  }

  #ExampleCaptcha_CaptchaImageDiv a {
    display:none !important;
  }
}


.rental-line > div {
  text-align: center;
}

.rental-line label {
  display: block;
  font-family: 'Quicksand';
  font-size: 1.8em;
}

.rental-line input {
  background-color: black;
}

#captchaCode {
  margin-top: 1em;
  background: white;
  border: 2px solid black;
  border-radius: 1em;
  color: black;
  padding: 0.2em;
  padding-left: 1em;
}

button {
  border-radius: 0;
  background-color: black;
  color: white;
  padding-left: 1em;
  padding-right: 1em;
}

.message {
  margin-top:1em;
  margin-bottom:1em;
  padding:1em;
  background-color: rgb(34, 184, 118);
  border-radius:1em;
  color: white;
}