

.rd-top-background {
  width:100%;
  height:600px;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  color: white;
  position: relative;

  .content {
    position: absolute;
    top:45%;

    h1 {
      border-bottom: 2px solid white;
      display: inline-block;
      font-size: 3em;
      font-weight: 600;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.23);
    }

    h2 {
      font-size: 2.2em;
      font-weight: 200;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.23);
    }

    p {
      font-size: 1.2em;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.23);
    }
  }
}

.rt-no-background {
  nav {
    background-color: black;
  }
}

.tr-top-section, .tr-next-section, .tr-blue-section, .tr-green-section, .tr-bottom-section, footer, .home-teaser {
  //padding-top:4em;
  //padding-bottom:4em;
  margin-bottom:0;
  margin-top:0;

  h3 {
    display:inline-block;
    font-weight: bold;
    font-size: 1.8em;
  }

  h3 {

    span::after {
      content: '';
      display:block;
      border-top-width: 5px;
      border-top-style: solid;
      width: 50px;
    }

  }

  h6 {
    font-weight: 600;
    line-height: 1.5em;
  }
}

.tr-top-section {

}

.tr-next-section {
  background-position-y: center;
  background-size: cover;
}

.tr-blue-section {
  background-color: rgb(80, 133, 255);
  color: white;

  h4 {

  }

  .bz-list-item {
    clear: left;
    margin-top: 1em;

    .bz-list-number {
      font-size: 1.5em;
      font-weight: bold;
      float: left;
    }
    .bz-list-text {
      font-size: 0.8em;
      font-weight: normal;
      margin-left: 3em;
      margin-top: 0.5em;
      position: relative;
      top: 0.5em;
    }
  }
  a {
    background-color: white !important;
    color: rgb(80, 133, 255) !important;
    padding: 2em 0em;
    border-radius: 1em;
  }
}

.tr-green-section {
  background-color: rgb(34, 184, 118);
  color: white;


  li {
    background-color:white;
    color:black;
    border-radius: 5em;
    margin-top: 0.5em;
    list-style: none;
    padding: 0.5em;
    padding-left: 1em;
  }

  a {
    font-family: 'Quicksand';
    background-color:black;
    color:white;
    border-radius: 5em;
    margin-top: 2em;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0.5em;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

.tr-bottom-section {

}



footer {
  background-color: #000000;
  min-height: 250px;
  color: #FFFFFF;

  a.nav-link {
    color: #FFFFFF;
    padding: 0;
  }
}

a.auto-link {
  background-color: black;
  color: white;
  padding: 0.1em 1.8em .1em;
  margin-top: .2em;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .rd-top-background {
    .content {
      position: absolute;
      top:5em;
      top:45%;

      h1 {
        font-size: 1.5em;
      }

      h2 {
        font-size: 1em;
      }

      p {
        font-size: 1em;
      }
    }
  }

  footer {
    img {
      width:100%;
    }
  }

}