.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #9db83b;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.containerTime {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

.containerTime::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -13px;
  background-color: white;
  border: 4px solid #9db83b;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}


.leftTime {
  left: 0;
}


.rightTime {
  left: 50%;
}


.leftTime::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

.rightTime::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

.rightTime::after {
  left: -13px;
}

.content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

@media screen and (max-width: 600px) {
  /* Place the timelime to the leftTime */
  .timeline::after {
    left: 31px;
  }
  
  .containerTime {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }
  
  .containerTime::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }
  
  .leftTime::after, .rightTime::after {
    left: 15px;
  }
  
  .rightTime {
    left: 0%;
  }
}