@media (min-width: 768px) {
    .navbar-brand.abs
    {
        position: absolute;
        width: 100%;
        left: 0;
        text-align: center;
    }
}



nav {
    //text-shadow: 1px 1px 2px rgba(0,0,0,0.33);
    .dropdown-menu {
        background-color: rgba(46, 53, 57, 0.9);
        color: black;
        border-radius: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    a.nav-link {
        font-family: 'Quicksand';
        font-weight: 600;
    }
    a.nav-link:hover {
        //color: white;
        background-color: #2e3539;
    }
    .menu-primary {
        .dropdown-menu {
            //border-top: 0;

            .dropdown-item {

            }
        }
    }
    .menu-secondary {
        border:0;
        z-index:1000;
        .dropdown-menu {
            border:0;
        }
    }

    .menu-icon-only {
        margin-left: 3em;
    }

    .dropdown-item {
        color: white;
    }

    .dropdown-item:hover {
        color: white;
        background-color: transparent;;
    }
    .custom-toggler.navbar-toggler {
       // border-color: #FFFFFF;
    }
    .bar1, .bar2, .bar3 {
        width: 25px;
        height: 2px;
        //background-color: #FFFFFF;
        margin: 6px 0;
    }

}
.megamenu {
    position: static
}

.megamenu .dropdown-menu {
    border: none;
    width: 100%;
    //background-color: #FFFFFF;
}


.text-uppercase {
    letter-spacing: 0.08em
}

.dropdown-item {
   // background-color: #FFFFFF;
}
.dropdown-menu {
    border: 0px;
}



