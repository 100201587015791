.favorite {
  text-align: center;
  background-color: #f0f0f0;
  border-radius: 1em;
  padding: 2em;
}

.favorite-card {
  padding-top: 300px;
}

.favorite-btn {
  background-color: black;
  color: white;
  padding: 0px 30px;
  margin-top: .2em;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9em;
  float:left;
}

.favorite-btn:hover {
  background-color: #f0f0f0;
  color: #000;
}

.blog-intro {
  height: 220px;
  overflow-y: auto;
}