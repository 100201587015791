body {
  .margetopbody {
    padding-top: 100px
  }
}

nav {
  a {
    color:#fff;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.23);
  }


}

/* Video */
#videoPlayground {
  overflow:hidden;
}

.header-container {
  width: 100%;
  height: 1000px;
  position: relative;
  padding: 20px;
}
.video-container {
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
video {
  position: absolute;
  z-index: -1;
  opacity: 0.78;
  width: 100%;
}
.slogan{
  color: #FFFFFF;
  z-index: 1;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 15%;
  letter-spacing: 3px;
  font-size: 40px;
  width: 700px ;
  text-shadow: 2px 2px 5px #000;
}

.video-background {
  background: #000;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -99;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.video-foreground {
  top: -80px;
}

.video-background iframe {
  top: 0;
}

.history {
  height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.opa {
  Background-color: #e9ecef;
  opacity: 0.8;
  height: 500px;

}

h1, h2, h3, h4 {
  font-family: 'Quicksand';
}
