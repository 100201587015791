.tr-block {

  .block-image {
    height: 250px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
  }

  .block-image-team {
    height: 250px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
  }

  .block-image-icon,
  .block-image-icon120,
  .block-image-icon170{
    height: 200px;
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;
    background-position-y: center;
  }

  .block-image-contact {
    height: 200px;
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;
    background-position-y: bottom;
  }

  h5 {
    font-family: 'Quicksand';
    margin-top: 0.6em;
    font-weight: 500;
    font-size: 1.5em;
    text-align: center;
  }
}



.tr-block.block-iconleft {

  .block-image-iconleft {
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  h6 {
    margin-top: 1.4em;
  }
}

.tr-block.block-rounded {
  background-color: #f0f0f0;
  border-radius: 1em;
  padding:2em;

  .block-image-rounded {
    background-size: cover;
    height: 400px;
    border-radius: 1em;
    background-position-x: center;
  }

  h5 {
    text-align: left;
  }
}

.block-rates {


  .rates-title {
    background-color: black;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: .6em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
  }

  .rates-body {
    padding: 0 !important;
    margin:0;

    div {
      margin-top:4px;
      padding: .6em;
      background-color: white;
      text-align: center;
    }

    div:last-child {
      background-color: green;
      border-bottom-left-radius: 1em;
      border-bottom-right-radius: 1em;

      a {
        display: inline;
        background-color: transparent;
      }
    }
  }
}
@media only screen and (max-width: 990px) {
  .marge {
    margin-bottom: 20px;
  }
}