// customize some Bootstrap variables
//$primary: #9db83b;
//$dark: #000;
//$success: #efe42f;
//$warning: #b27822;
//$secondary: #6d7f29;
//$link-color: #000;
//$link-hover-color: #b3b3b3;
//$light: #fff;
//$navbar-light-color: #2E2E2E;
//$dropdown-link-color: #9db83b;
//$navbar-dark-color: #fff;
//$navbar-dark-hover-color: #9db83b;
//$navbar-dark-active-color: #9db83b;
//$dropdown-bg: #2f2e2e;
//$danger: #7a0000;
//$link-color: #9db83b;
//$link-hover-color: #b3b3b3;
//$btn-border-radius: 50px;
//$line-height-lg: 1.9;
//$btn-border-radius-lg: 5em;
//$form-group-margin-bottom: .75rem;
//$input-btn-padding-x: .9rem;
//$input-btn-padding-y: .9rem;
//$input-btn-padding-y-lg: .9rem;
//$input-btn-padding-x-lg: 5rem;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600&family=Source+Code+Pro:ital,wght@0,400;0,500;0,900;1,400;1,500;1,900&display=swap');

$font-family-base: 'Quicksand';