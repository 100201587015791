.block-edit-button {
  z-index: 1031;
  height: 1.8rem;
  position: absolute;
  top: .5rem;
  border: 1px solid #000;
  color: #FFF;
  background-color: #000;

  &:hover {
    color: #000;
    background-color: #FFF;
    & > i {
      color: #FFF;
      background-color: #000;
    }
  }

  & > i {
    position: absolute;
    color: #000;
    top: -.05rem;
    height: 1.8rem;
    width: 1.8rem;
    background-color: #FFF;
    border: 1px solid #000;
    border-radius: 1rem;
    padding: .3rem;
    &:hover {
      color: #FFF;
      background-color: #000;
    }
  }

  &.block-edit-button.left {
    left: 1.5rem;
    padding: .1rem .75rem .2rem 1.25rem;
    border-radius: 0 1rem 1rem 0;

    & > i {
      left: -1rem;
    }
  }

  &.block-edit-button.right {
    right: 1.5rem;
    padding: .1rem 1.25rem .2rem 0.75rem;
    border-radius: 1rem 0 0 1rem;

    & > i {
      right: -1rem;
    }
  }
}

.admin-modal {
  .modal-body {
    .nav-link {

    }
  }
}